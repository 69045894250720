/**@jsx myJSXConverter **/
import myJSXConverter from "../../myJSXConverter";

import { useSearchMobile } from "./useSearchMobile";

import searchIcon from "../../assets/search-1.svg";

import "./SearchMobile.css";

/**
 * Component reponsible for rendering the basic building blocks for the search popup
 *
 * @returns JSX component
 */
export const SearchBarMobile = () => {
  const { defaultValue, closePopup, onKeyUp, onSubmit } = useSearchMobile();

  return (
    <div className="gb-searchbar-mobile-wrapper">
      <form id="gb-searchbar-mobile-form" onSubmit={(e) => onSubmit(e)}>
        <input
          id="gb-searchbar-mobile-input"
          name="searchTerm"
          type="text"
          placeholder="Search here..."
          value={defaultValue()}
          onKeyUp={(e) => onKeyUp(e)}
        />
        <button type="submit" className="gb-searchbar-mobile-form-submit-btn">
          <img src={searchIcon} alt="search-icon" />
        </button>
      </form>
      <div id="gb-searchbar-mobile-popup-container">
        <div
          id="gb-searchbar-mobile-autocomplete-suggestions-container"
          className="gb-searchbar-mobile-autocomplete-suggestions-container"
          style="display:none;"
        ></div>
        <div
          id="gb-searchbar-mobile-filters-container"
          className="gb-searchbar-mobile-filters-container"
        ></div>
        <div
          id="gb-searchbar-mobile-products-container"
          className="gb-searchbar-mobile-products-container"
        ></div>
      </div>
    </div>
  );
};
